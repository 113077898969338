export default [
  {
    key: 'no',
    label: 'field.orderNo',
    type: 'text',
    operator: '%',
  },
  {
    key: 'transferDate',
    label: 'field.transferDate',
    type: 'date-range-picker',
    cast: 'range',
    firstOperator: 'd>=',
    secondOperator: 'd<=',
  },
  {
    key: 'status',
    label: 'field.status',
    selectionKey: 'value',
    selectionLabel: 'text',
    type: 'multi-selection',
    operator: 'in',
    translatable: false,
    options: [
      { text: 'Pending', value: 'Pending' },
      { text: 'Confirmed', value: 'Confirmed' },
    ],
  },
];
